// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-armaturer-js": () => import("./../../../src/pages/armaturer.js" /* webpackChunkName: "component---src-pages-armaturer-js" */),
  "component---src-pages-granit-js": () => import("./../../../src/pages/granit.js" /* webpackChunkName: "component---src-pages-granit-js" */),
  "component---src-pages-haandvaske-js": () => import("./../../../src/pages/haandvaske.js" /* webpackChunkName: "component---src-pages-haandvaske-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-komposit-js": () => import("./../../../src/pages/komposit.js" /* webpackChunkName: "component---src-pages-komposit-js" */),
  "component---src-pages-koncept-js": () => import("./../../../src/pages/koncept.js" /* webpackChunkName: "component---src-pages-koncept-js" */),
  "component---src-pages-kontakt-os-js": () => import("./../../../src/pages/kontakt-os.js" /* webpackChunkName: "component---src-pages-kontakt-os-js" */),
  "component---src-pages-laage-farver-js": () => import("./../../../src/pages/laage-farver.js" /* webpackChunkName: "component---src-pages-laage-farver-js" */),
  "component---src-pages-laager-js": () => import("./../../../src/pages/laager.js" /* webpackChunkName: "component---src-pages-laager-js" */),
  "component---src-pages-laminat-js": () => import("./../../../src/pages/laminat.js" /* webpackChunkName: "component---src-pages-laminat-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-trae-bordplader-js": () => import("./../../../src/pages/trae-bordplader.js" /* webpackChunkName: "component---src-pages-trae-bordplader-js" */)
}

